import mixins from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mainFilter from '../../components/filter/index.vue'
import moment from 'moment'
import { camelCase, debounce, snakeCase } from 'lodash'

export default {
  name: 'action-plans',
  mixin: [mixins],
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY HH:mm')
    }
  },
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      filter: ''
    }
  },
  components: {
    mainFilter
  },
  computed: {
    ...mapGetters({
      list: 'homeWorkResult/list',
      limit: 'homeWorkResult/limit',
      skip: 'homeWorkResult/skip',
      isListLoading: 'homeWorkResult/isListLoading',
      listLength: 'homeWorkResult/listLength',
      isLoading: 'homeWorkResult/isLoading',
      setting: 'courses/setting',
      simpleCoursesList: 'homeWorkResult/simpleList',
      filterCourseId: 'homeWorkResult/filterCourseId'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Started at',
          value: 'startedAt'
        },
        {
          text: 'Finished at',
          value: 'finishedAt'
        },
        {
          text: 'Module',
          value: 'module'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Student',
          value: 'user'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
    getLocalStorageFilter () {
      return JSON.parse(localStorage.getItem('home_works_filters'))
    },
    getLocalStorageSearch () {
      return JSON.parse(localStorage.getItem('home_works_search'))
    },
    getLocalStorageFilterCourseId () {
      return localStorage.getItem('home_works_filter_course_id')
    }
  },
  created () {
    const courseId = +this.$route.params.courseId
    if (!!this.filterCourseId && this.filterCourseId !== courseId) {
      this.changeFilter()
    } else {
      this.getLocalStorageDataFilter()
    }

    if (!this.list.length) {
      this.fetchList(this.$route.params)
    }
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    this.courseList(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'homeWorkResult/GET_LIST',
      remove: 'homeWorkResult/DELETE',
      reset: 'homeWorkResult/RESET',
      courseList: 'homeWorkResult/COURSE_LIST'
    }),
    ...mapMutations({
      setCurrentItem: 'homeWorkResult/SET_ITEM',
      setListContent: 'homeWorkResult/SET_LIST',
      setList: 'homeWorkResult/SET_LIST',
      changeFixBody: 'system/FIX_BODY',
      changeSkip: 'homeWorkResult/CHANGE_SKIP',
      changeSort: 'homeWorkResult/CHANGE_SORT',
      changeFilter: 'homeWorkResult/CHANGE_FILTER',
      setFilterData: 'homeWorkResult/setFilterData',
      setFilterCourseId: 'homeWorkResult/setFilterCourseId'
    }),
    customSort (items, nameField, isDesc) {
      const toSnakeIndex = snakeCase(nameField[0])
      let sortData = ''

      if (!nameField.length) {
        this.changeSort(sortData)
        this.changeSkip(0)

        // this.fetchList(this.$route.params)

        return items
      }

      if (nameField) {
        if (isDesc) {
          sortData = `&sorting[field]=${toSnakeIndex}&sorting[direction]=desc`
        } else {
          sortData = `&sorting[field]=${toSnakeIndex}&sorting[direction]=asc`
        }
      } else {
        sortData = ''
      }

      this.changeSort(sortData)
      this.changeSkip(0)

      return items
    },
    toCamelCase (str) {
      return camelCase(str)
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.$route.params)
    },
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },

    resetListItem () {
      this.reset(this.removingItem).then(() => {
        this.showRemoveDialog = false;
        this.$toasted.success('Success');

        this.fetchList(this.$route.params)
        
      })
    },

    updateFilter (params) {
      this.filter = params
    },

    onChangeFilter () {
      this.page = 1
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      item.course_id = this.$route.params.course_id
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList(this.$route.params)
    }, 1000),
    hasShowCancel (item) {
      if (item.status.data.title === 'canceled' || item.status.data.title === 'to_retake' ) return false;

      return true
    },
    getLocalStorageDataFilter () {
      const courseId = +this.$route.params.courseId
      this.setFilterCourseId(+this.getLocalStorageFilterCourseId)
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        this.setFilterData(this.getLocalStorageFilter)
      } else {
        localStorage.removeItem('home_works_filters')
        localStorage.removeItem('home_works_filter_course_id')
      }
    }
  },
  destroyed () {
    this.setListContent([])
    this.setList([])
  }
}
