import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import validation from '@/mixins/validation'
import mixins from '@/mixins'
import moment from 'moment'

export default {
  name: 'filter-home-works',
  mixin: [mixins, validation],
  data () {
    return {
      dateRangeText: '',
      menuFrom: false,
      menuTo: false,
      search: '',
      searchInput: '',
      filter: {
        dateFrom: '',
        dateTo: '',
        status: '',
        user: '',
        module: '',
        homeWork: '',
        graduateWork: '',
        isChecking: true
      }
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      filters: 'homeWorkResult/filters',
      currentCourse: 'homeWorkResult/currentCourse',
      isLoadingCourse: 'homeWorkResult/isLoadingCourse',

      basicUsers: 'user/basicUsers',
      basicUsersLoading: 'user/basicUsersLoading',
      homeWorks: 'homeWorkResult/homeWorks',
      graduateWork: 'homeWorkResult/graduateWork',
      filterCourseId: 'homeWorkResult/filterCourseId'
    }),
    typesHomeWork () {
      return [
        {
          name: 'Testing',
          id: 1
        },
        {
          name: 'Detailed response',
          id: 2
        }
      ]
    },
    getLocalStorageFilter () {
      return JSON.parse(localStorage.getItem('home_works_filters'))
    },
    getLocalStorageSearch () {
      return JSON.parse(localStorage.getItem('home_works_search'))
    },
    getLocalStorageFilterCourseId () {
      return localStorage.getItem('home_works_filter_course_id')
    }
  },
  created () {
    this.setFilter()
    this.getLocalStorageDataFilter()
    this.fetchCourse(this.$route.params)
    this.fetchHomeWorks(this.$route.params)
    this.fetchGraduateWork(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'homeWorkResult/GET_LIST',
      fetchUsersBasic: 'user/GET_BASIC_USERS',
      fetchCourse: 'homeWorkResult/GET_COURSE_MODULES',
      fetchHomeWorks: 'homeWorkResult/GET_COURSE_HOME_WORKS',
      fetchGraduateWork: 'homeWorkResult/GET_COURSE_GRADUATE_WORK'
    }),
    ...mapMutations({
      changeFilter: 'homeWorkResult/CHANGE_FILTER',
      setFilterCourseId: 'homeWorkResult/setFilterCourseId',
      setFilterData: 'homeWorkResult/setFilterData'
    }),
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 500),
    setFilter () {
      const courseId = +this.$route.params.courseId
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        Object.assign(this.filter, this.filters)
        // this.autoCompleteChanged({
        //   full_name: this.filters.user
        // })
      }
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // users
    changeInputUsers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchUsersBasic({
          keywords: val
        })
      }
    }, 500),
    changeFilterLocal (val, type) {
      this.setFilterCourseId(+this.$route.params.courseId)
      if (typeof val === 'undefined') val = ''
      this.changeFilter({
        value: val,
        type: type
      })
      localStorage.setItem('home_works_filters', JSON.stringify(this.filters))
      localStorage.setItem('home_works_filter_course_id', +this.$route.params.courseId)
      this.fetchList(this.$route.params)
      this.$emit('change')
      if (type === 'module' && val !== '') {
        this.fetchHomeWorks({ moduleId: val })
      } else {
        this.fetchHomeWorks(this.$route.params)
      }
    },
    autoCompleteChanged (e, type) {
      if (!e) {
        this.filter.user = ''
        this.search = ''
      } else {
        this.filter.user = e.id
        this.search = e
      }
      this.changeFilter({
        value: e.hasOwnProperty('id') ? e.id : '',
        type: type
      })
      this.fetchList(this.$route.params)
      localStorage.setItem('home_works_filters', JSON.stringify(this.filters))
      localStorage.setItem('home_works_filter_course_id', +this.$route.params.courseId)
    },
    filterUsersAutoComplete (item, queryText) {
      return item.full_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.id.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    handleResetFilter () {
      this.changeFilter(null)
      for (const i in this.filter) {
        this.filter[i] = ''
      }
      this.filters.isChecking = '1'
      this.search = ''
      this.$refs.datetimeFrom.clearHandler()
      this.$refs.datetimeTo.clearHandler()
    },
    getLocalStorageDataFilter () {
      const courseId = +this.$route.params.courseId
      this.setFilterCourseId(+this.getLocalStorageFilterCourseId)
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        this.setFilterData(this.getLocalStorageFilter)
        Object.assign(this.filter, this.filters)
        // this.autoCompleteChanged({
        //   full_name: this.filters.user
        // })
      } else {
        localStorage.removeItem('home_works_filters')
        localStorage.removeItem('home_works_filter_course_id')
      }
    }
  }
  // destroyed () {
  //   const courseId = +this.$route.params.courseId
  //   if (!!this.filterCourseId && this.filterCourseId !== courseId) this.changeFilter()
  // }
}
